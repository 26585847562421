import { Card, Avatar } from "antd";
import Meta from "antd/es/card/Meta";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { host } from "../../config";
import "./Print.css";

export default function App() {
    const [data, setData] = useState();
    const token = localStorage.getItem("token");
    const [selectedElement, setSelectedElement] = useState(null);
    const [config, setConfig] = useState();
    const location = useLocation();
    const data_ = location.state;
    console.log(data_);
    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err.message));
    }, []);

    var all_plus = 0;
    const sums = {};
    var all_weight = 0;
    var tradeSumm = 0;
    var clientSumm = 0;
    var productSumm = 0;

    for (let t of data_.trade) {
        const owner = t.owner.toUpperCase();
        const weight = t.weight;
        const plus = t.plus;

        if (sums[owner]) {
            sums[owner].weight += weight;
            sums[owner].plus += plus;
        } else {
            sums[owner] = { weight, plus };
        }
        tradeSumm += t.plus;
    }
    for (let c of data_.client) {
        const owner = c.owner.toUpperCase();
        const weight = c.weight;
        const client = parseFloat(c.plus);
        if (sums[owner]) {
            if (sums[owner].client) {
                sums[owner].client += c.plus;
            } else {
                sums[owner].client = c.plus;
            }
        } else {
            sums[owner] = { weight, client };
        }
        clientSumm += c.plus;
    }
    for (let x of data_.products) {
        if (x.weight) {
            all_plus -= x.weight;
            productSumm += parseFloat(x.weight);
        }

        if (x.usd) {
            all_plus -= (x.usd * config?.dollar_price) / config?.gold_price;
            productSumm += parseFloat(
                (x.usd * config?.dollar_price) / config?.gold_price,
            );
        }
        if (x.som) {
            all_plus -= x.som / config?.gold_price;
            productSumm += parseFloat(x.som / config?.gold_price);
        }
    }

    return (
        <>
            <div>
                <div
                    className="flex"
                    style={{
                        width: "100%",
                        padding: "10px",
                    }}
                >
                    <div
                        style={{
                            alignContent: "left",
                        }}
                    >
                        Nomi: {data_?.name}
                        <br />
                        Time: {data_?.date}
                    </div>
                    <div
                        style={{
                            margin: "auto",
                        }}
                    >
                        Sovda: {tradeSumm.toFixed(2)}
                        <br />
                        Mijoz: {clientSumm.toFixed(2)}
                    </div>
                    <div
                        style={{
                            padding: "20px",
                            color: "white",
                            borderRadius: "15px",
                            backgroundColor:
                                tradeSumm + clientSumm - productSumm < 0
                                    ? "red"
                                    : "green",
                        }}
                    >
                        {(tradeSumm + clientSumm - productSumm).toFixed(2)}
                    </div>
                </div>
                <div className="element">
                    {Object.entries(sums).map(([owner, values], index) => (
                        <div key={owner} className="responsive-column">
                            <Card
                                className="dashboard-card"
                                style={{
                                    border:
                                        selectedElement === index
                                            ? "2px solid #1890ff"
                                            : "",
                                    cursor: "pointer",
                                }}
                            >
                                <Meta
                                    avatar={
                                        <Avatar
                                            className="avatarIcon"
                                            size={"large"}
                                        >
                                            {owner}
                                        </Avatar>
                                    }
                                    title={values.weight.toFixed(2)}
                                />
                                <div
                                    className="center"
                                    style={{ marginTop: "5px" }}
                                >
                                    Savdo: {values.plus?.toFixed(2) || "0"}{" "}
                                    <br />
                                    Mijoz: {values.client?.toFixed(2) || "0"}
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
                <div
                    className="flex"
                    style={{
                        width: "80%",
                        margin: "0 auto",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            margin: "20px",
                        }}
                    >
                        <h2>Keldi</h2>
                        {data_.products.map((product, index) => (
                            <div key={index}>
                                {/* Bu yerda product ma'lumotlarini chiqarish mumkin */}
                                <p>
                                    {index + 1}. {product.name}:{" "}
                                    {product.weight ||
                                        product.som ||
                                        product.usd}
                                </p>
                            </div>
                        ))}
                        <hr />
                        {productSumm.toFixed(2)}
                    </div>
                    <div
                        style={{
                            margin: "20px",
                        }}
                    >
                        <h2>Taqsim</h2>
                        {data_.taqsim.map((product, index) => (
                            <div key={index}>
                                {/* Bu yerda product ma'lumotlarini chiqarish mumkin */}
                                <p>
                                    {" "}
                                    {index + 1}. {product.name}:{" "}
                                    {product.weight ||
                                        product.som ||
                                        product.usd}
                                    ; {product.owner}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div>
                        <div
                            style={{
                                margin: "20px",
                            }}
                        >
                            <h2>Mijoz</h2>
                            {data_.client.map((product, index) => (
                                <div key={index}>
                                    {/* Bu yerda product ma'lumotlarini chiqarish mumkin */}
                                    <p>
                                        {" "}
                                        {index + 1}. {product.name}{" "}
                                        {product.plus ||
                                            product.som ||
                                            product.usd}
                                    </p>
                                </div>
                            ))}
                            <hr />
                            {clientSumm.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
