import { useEffect, useState } from "react";
import { Form, Table, Button, Modal, Input, Select, InputNumber } from "antd";
import { useNavigate } from "react-router-dom";
import { host } from "../../config";
export default function App(props) {
    const token = localStorage.getItem("token");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const navigate = useNavigate();
    const _id = window.location.pathname.split("/")[3];

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    }, []);

    const del = (e) => {
        fetch(host + "/api/divison/id/" + _id + "/taqsim/del", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchPost = (e) => {
        fetch(host + "/api/divison/id/" + _id + "/taqsim", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{props.data.taqsim.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Vesi",
            dataIndex: "",
            key: "weight",
            render: (e) => {
                if (e.weight) {
                    return e.weight;
                }

                if (e.usd) {
                    return (
                        (e.usd * (props?.data?.dollar_price || config?.dollar_price)) /
                        (props?.data?.gold_price || config?.gold_price)
                    ).toFixed(2);
                }

                if (e.som) {
                    return (e.som / (props?.data?.gold_price || config?.gold_price)).toFixed(2);
                }
            },
        },

        {
            title: "Owner",
            dataIndex: "owner",
            key: "owner",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "O'chirish",
            dataIndex: "",
            key: "x",
            render: (e) => (
                <Button type="primary" onClick={() => del(e)} danger>
                    Delete
                </Button>
            ),
        },
    ];

    const sums = {};

    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.weight;
            const plus = item.plus;

            if (sums[owner]) {
                sums[owner].weight += weight;
                sums[owner].plus += plus;
            } else {
                sums[owner] = { weight, plus };
            }
        }
    }
    var all_plus = 0;
    data?.map((el) => (all_plus += el.plus));

    var all_weight = 0;
    data?.map((el) => (all_weight += el.weight));

    var selectInput = [];
    props?.data?.products.map((el) =>
        selectInput.push({
            value: el.name || "NotSelected",
            label: el.name || "Not Selected",
            el: el,
        }),
    );

    return (
        <>
            <div className="center">
                <Button
                    type="primary"
                    style={{
                        margin: "20px",
                    }}
                    onClick={showModal}
                >
                    Taqsim
                </Button>

                <>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <p
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    {record.description}
                                </p>
                            ),
                            rowExpandable: (record) => record.description,
                        }}
                        dataSource={props?.data?.taqsim}
                        pagination={false}
                    />

                    <Modal
                        title="Taqsimlash"
                        footer={null}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <Form
                            onFinish={(e) => {
                                fetchPost(e);
                            }}
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                            }}
                        >
                            <Form.Item
                                name="name"
                                label="Nomi"
                                rules={[
                                    {
                                        required: true,
                                        message: "Nomini yozing",
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    tokenSeparators={[","]}
                                    placeholder="All"
                                    options={selectInput}
                                    onChange={(value, option) => {
                                        setSelected(option.el); // Tanlangan elementni saqlash
                                        // form.setFieldsValue({ name: value }); // Form qiymatini yangilash
                                    }}
                                />
                            </Form.Item>
                            {selected && (
                                <>
                                    <div>{selected.weight}</div>
                                    <div>{selected.som}</div>
                                    <div>{selected.usd}</div>
                                </>
                            )}
                            <Form.Item
                                name="owner"
                                label="Owner"
                                rules={[
                                    {
                                        required: true,
                                        message: "Nomini yozing",
                                    },
                                ]}
                                required
                            >
                                <Input placeholder="" />
                            </Form.Item>
                            {selected?.weight && (
                                <Form.Item name="weight" label="GOLD">
                                    <Form.Item name="weight" noStyle>
                                        <InputNumber
                                            style={{
                                                width: "auto",
                                            }}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            )}

                            {selected?.som && (
                                <Form.Item name="som" label="SOM">
                                    <InputNumber
                                        style={{
                                            width: "auto",
                                        }}
                                    />
                                </Form.Item>
                            )}

                            {selected?.usd && (
                                <Form.Item name="usd" label="USD">
                                    <Form.Item name="usd" noStyle>
                                        <InputNumber
                                            style={{
                                                width: "auto",
                                            }}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            )}

                            <Form.Item name="comment" label="Comment">
                                <Input placeholder="" />
                            </Form.Item>
                            <Button
                                type="primary"
                                style={{
                                    width: "100%",
                                }}
                                htmlType="submit"
                            >
                                Ayirish
                            </Button>
                        </Form>
                    </Modal>
                </>
            </div>
        </>
    );
}
